import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import styled from 'react-emotion'

import Layout from '../components/layout'
import Paragraph from '../components/paragraph'

const MobileContainer = styled('div')`
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    padding: 50px;
    width: 1024px;
  }

  @media screen and (max-width: 1023px) {
    padding: 20px;
    width: 100%;
  }
`

const ArticlesPage = ({ data, location }) => (
  <Layout location={location}>
    <MobileContainer>
      <h1>Articles</h1>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <div key={node.id}>
          <div>{node.frontmatter.date}</div>
          <h3>
            <Link to={`/articles${node.frontmatter.path}`}>{node.frontmatter.title}</Link>
          </h3>
          <Paragraph>{node.excerpt}</Paragraph>
        </div>
      ))}
    </MobileContainer>
  </Layout>
)

ArticlesPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
}

export default ArticlesPage

export const query = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 400)
          frontmatter {
            title
            path
            date
          }
        }
      }
    }
  }
`
